import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import './presentation.scss';

export function Presentation() {
    const { t } = useTranslation();
    const nbyears = new Date().getFullYear() - 2015;
    return (
        <>
            <div className='presentation'>
                <div className='p_text'>
                    <h1 className='p_title'>{t('presentation.title')}</h1>
                    <Trans className='p_description'>{t('presentation.description', { nbyears: nbyears })}</Trans>
                    <div className='p_links'>
                        <a href='https://www.linkedin.com/in/thomas-chiroussot-chambeaux/'>
                            <FontAwesomeIcon icon={faLinkedin} size='2x'/>
                        </a>
                        <a href='mailto:contact@activiti.fr'>
                            <FontAwesomeIcon icon={faEnvelope} size='2x'/>
                        </a>
                    </div>
                </div>
                <img className='p_picture' src='/thomas.jpg' alt='image'/>
            </div>
            <FontAwesomeIcon className='p_scrollDown' icon={faAnglesDown} size='2x'/>
        </>
    );
}