import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './config/i18n';
import './index.scss';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<StrictMode>
		<Suspense>
			<App />
		</Suspense>
	</StrictMode>
);