import { Experience } from './components/experience/experience';
import { Presentation } from './components/presentation/presentation';
import './App.scss';
import { LanguageSelector } from './components/language/selector';

function App() {
	return (
		<>
			<header className='app_header'>
				<h1 className='company'>Chambeaux</h1>
				<LanguageSelector/>
			</header>
			<main className="app">
				<Presentation/>
				<Experience/>
			</main>
			<footer className='app_footer'>
				<text className='copyright'>© SARL Chambeaux {new Date().getFullYear()}</text>
			</footer>
		</>
	);
}

export default App;
