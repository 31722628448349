import { ExperienceItem } from './item/item';
import { ExperienceModel } from '../../models/experience';
import './experience.scss';


export function Experience() {
    const items: Array<ExperienceModel>= [
        {
            name: "activity",
            imgUrl: '/activiti.jpg',
            website: 'https://activiti.fr'
        },
        {
            name: "mycoachpro",
            imgUrl: '/mycoachpro.png',
            website: 'https://mycoach.pro'
        },
        {
            name: "nicecactus",
            imgUrl: '/nicecactus.png',
            website: 'https://nicecactus.gg'
        },
        {
            name: "outpost24",
            imgUrl: '/outpost.png',
            website: 'https://outpost24.com'
        },
        {
            name: "secludit",
            imgUrl: '/secludit.jpg',
            website: 'https://secludit.com'
        },
    ];

    return (
        <div className='experience'>
            <h1 className='e-title'>Experience</h1>
            {
                items.map((x, i) => <ExperienceItem key={x.name} item={x} reverse={i % 2 == 1}/>)
            }
        </div>
    )
}