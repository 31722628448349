import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExperienceModel } from '../../../models/experience';
import './item.scss';


interface ExperienceItemProps {
    item: ExperienceModel;
    reverse: boolean;
}

export const ExperienceItem: FC<ExperienceItemProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={`experienceItem ${props.reverse ? 'reverse' : ''}`}>
            <div className='textBlock'>
                <h1 className='tb_title'>{t(`experiences.${props.item.name}.title`)}</h1>
                <div className='tb_date'>
                    <Trans>{t(`experiences.${props.item.name}.start`)}</Trans>
                    <span>-</span>
                    <Trans>{t(`experiences.${props.item.name}.end`)}</Trans>
                </div>
                <Trans className='tb_description'>{t(`experiences.${props.item.name}.description`)}</Trans>
                <div className="tb_tags">
                    {(t(`experiences.${props.item.name}.tags`, {returnObjects: true}) as Array<string>).map(x => <span className='tb_tag'>#{x}</span>)}
                </div>
            </div>
            <img className='imgBlock' src={props.item.imgUrl} alt='image'/>
        </div>
    );
}