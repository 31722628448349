import { useTranslation } from 'react-i18next';
import Select, {SingleValue, ActionMeta} from 'react-select';
import './selector.scss';

export function LanguageSelector() {
    const { i18n } = useTranslation();

    const options = [
        {value: 'en', label: 'English'},
        {value: 'fr', label: 'Français'}
    ]

    const updateLanguage = async (e: SingleValue<{value: string, label: string}>, m: ActionMeta<{value: string, label: string}>) => {
        await i18n.changeLanguage(e?.value);
    }
    

    return (
        <Select
            classNamePrefix='selector'
            value={options.find((x) => x.value === i18n.language) || options[0]}
            options={options}
            onChange={updateLanguage}
        />
    );
}